.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px;
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 1000;
}

.navbar-logo {
    display: flex;
    align-items: center;

}

.navbar-logo img {
    /* height: 24px; */
   /* margin: 5px 10px;*/
    object-fit: cover;
}

.navbar-logo span {
    /* font-size: 1.1rem; */
    color: #1c1e21;
    margin-left: -9px;
    font-weight: 600;
    font-size: 18.5px;
}

.navbar-search-container {
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 0px;
    font-size: 15px;
}

.navbar-search {
    display: flex;
    align-items: center;
    background-color: #f5f6f7;
    border-radius: 20px;
    padding: 5px 10px;
}

.navbar-search input {
    border: none;
    background: none;
    padding: 7px;
    outline: none;
    width: 100%;
}

.navbar-search button {
    background: none;
    border: none;
    color: #717377;
    cursor: pointer;
}

.navbar-lang {
    display: flex;
    align-items: center;
    /* background-color: #D8DADF; */
    border-radius: 20px;
    padding: 5px 10px;
}

.navbar-icon {
    display: flex;
    align-items: center;
    /* background-color: #f5f6f7;/ */
    /* border-radius: 50%; */
    /* padding: 5px; */
    width: 10%;
}

.navbar-icon .profile-image {
    width: 90%;
    /* height: 24px; */
    margin: 0px auto;
    border-radius: 50%;
    /* border: 1px solid red; */

}


.navbar-lang .lang-btn {
    border: none;
    background: none;
    color: #050505;
    font-size: 1.0625rem;
    cursor: default;
    font-weight: 600;
    padding: 10px;
    background-color: #e4e6eb;
    border-radius: 5px;
}

.navbar-lang>i {
    background-color: #e4e6eb;
    margin: 0px 10px;
    padding: 10px 15px;
    border-radius: 50%;
    font-size: 20px;
}

.check {
    display: none;
    justify-content: center;
    align-items: center;
}

#check {
    display: none;
}

.checkbtn {
    color: black;
    font-size: 30px;
    cursor: pointer;
    display: none;
    margin: 0 20px;
}

.navbar-sidebar {
    display: none;
}
.navbar-logo img {
    /* height: 24px; */
    /* margin: 5px 0px; */
    width: 19.5% !important;
}

@media (max-width: 1000px) {
    .navbar-logo span {
        font-size: 1.5rem;

    }
}

@media (max-width: 800px) {
    .checkbtn {
        display: block;
    }

    .navbar-logo span {

        font-size: 1.2rem;
    }


    
.navbar-logo img {
    /* height: 24px; */
    margin: 5px 0px;
    width: 22% !important;
}

    .navbar-lang {
        display: none;
    }

    .sidebar {
        display: none;
    }

    .navbar-icon {
        display: none;
    }

    .check {
        display: flex;
    }

    .navbar-sidebar {
        display: block;
        margin-left: -40px;
        /* margin: -10px auto; */
        /* padding: -10px; */
        /* text-align: left; */
        /* border: 1px solid red; */
        width: 100%;
    }

    .navbar-search-container {
        position: fixed;
        width: 100%;
        height: 100vh;
        background: white;
        text-align: center;
        top: 60px;
        left: -100%;
        /* transition: all .5s; */
        flex-direction: column;
        padding-top: 20px;
        overflow: scroll;
    }

    .navbar-search {
        margin-bottom: 20px;
    }

    .navbar-lang {
        margin-bottom: 20px;
    }

    .navbar-search-container>.navbar-search {
        /* border:1px solid red; */
        width: 90%;

    }

    #check:checked~.navbar-search-container {
        left: 0;
    }
}