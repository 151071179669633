.page2-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 0px;
  width: 100%;
  max-width: 1000px;
  margin: 5% auto;
}

.page2-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 11px;
  width: 100%;
  text-align: left;
  padding-left: 17px;
}

.page2-avatar-section {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-left: 15px;
}

.page2-avatar {
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 5px;
}

.page2-avatar-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #A0D468;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 35px;
  color: #388E3C;
  font-weight: bold;
  margin-right: 10px;
}

.page2-avatar-text {
  display: flex;
  flex-direction: column;
}

.page2-avatar-maintext {
  margin: 0;
  padding: 0;
}

.page2-avatar-subtext {
  font-size: 14px;
  color: #313131;
  margin: -5%;
  padding: 0;
  padding-left: 5.5%;
}

.page2-check-mark {
  position: relative;
  top: 22px;
  right: 78.5%;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: white;
  border: 2px solid #ffffff;
  border-radius: 50%;
  background-color: #868686;
}

.page2-description {
  width: 100%;
  text-align: left;
  margin-bottom: 25px;
  color: #5f5f5f;
  padding-left: 17px;
  padding: 10px;
  margin-left: 25px;
  
}

.break-word {
  word-wrap: break-word;
  word-break: break-all;
  color: rgb(7, 35, 158);
}

.goodnews{
  color: #5f5f5f;
  font-weight: 600 ;
  font-size: 16px !important;
}
.page2-main-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 20px;
}

.page2-left-column {
  width: 59%;
  font-size: 16.3px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.page2-right-column {
  width: 37%;
}

.page2-section, .page2-side-section {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.07);
}

.page2-community-section {
  margin-bottom: 20px;
}

.page2-find-out-more-section .page2-cards {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.page2-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 48%;
  margin: 10px 0;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  text-align: left;
  position: relative;
}

.page2-card-image {
  width: 100%;
  border-radius: 8px 8px 0px 0px;
  margin-bottom: 10px;
}

.page2-card span {
  padding: 0px 8px 8px 8px;
}

.page2-arrow {
  background-color: #f0f2f5;
  border-radius: 50%;
  width: 20px; /* Reduced width */
  height: 20px; /* Reduced height */
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #606770; /* Adjust the color to match the icons in the images */
  font-size: 16px; /* Reduced font size */
}


.page2-arrow.left {
  left: -20px; /* Adjust positioning as needed */
  z-index :999 !important;
  /* width: 30px; */

}

.page2-arrow.right {
  right: -20px; /* Adjust positioning as needed */
  z-index :999 !important;

}

.page2-arrow:hover {
  background-color: #e0e0e0;
}

.page2-section-header {
  font-size: 18px;
  font-weight: bold;
  /* margin-bottom: 10px; */
 /* line-height: 10px;*/
}

.page2-section-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.page2-section-item span {
  font-size: 14px;
  margin-top: 10px;
}

.page2-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  color: #4CAF50;
  margin-top: 10px;
}

@media (max-width: 1300px) {
  .page2-content {
    width: 97%;
  }
  
  .page2-main-section {
    flex-direction: column;
    align-items: center;
  }

  .page2-left-column {
    order: 1;
    width: 100%;
  }

  .page2-right-column {
    order: 2;
    width: 100%;
  }

  /* yoou see i gave priority to last */
 /*  .page2-find-out-more-section {
    order: 7;
    width: 90%;
  } */
}

.account-status-box,
.right-box {
  background-color: #fff;
  border-radius: 8px;
  /* padding: 20px; */
  margin-bottom: 20px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.account-status-header,
.right-box-header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
}

.account-status-content,
.right-box-content {
  display: flex;
  align-items: center;
}

.status-icon {
  background-color: #f0f2f5;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.info-circle-icon {
  color: #1c1e21;
  font-size: 20px;
}

.status-text {
  flex: 1;
}
.status-text p{
  font-size: 14px;
  line-height: 19px;

}


.nores{
  line-height: 10px;
}
.status-title {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 5px;
}

.chevron-icon {
  color: #606770;
  font-size: 18px;
  margin-left: auto;
}

.page2-right-column .right-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.page2-right-column .right-box-content {

  
}


.goodnews-icon {
  background-color: #f0f2f5;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-size: 16px;
  color: #5f5f5f;
  margin-top: 10px;
}
.right-box-content,.account-status-box{
  /* width: 90%; */
  
  padding: 10px;
}
.right-box-content:hover{
  
  background-color: #F2F2F2;
}
.right-box-header {
  padding: 10px 10px 0px  10px;
}

