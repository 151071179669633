.headerx1 {
  display: flex;
  align-items: center;
  padding: 0.2px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff; /* Replace with your desired color */
  z-index: 1000;
}

.header-logo2 {
  width: 58px;
  height: 58px;
  background-image: url('./xaxa1.png'); /* Adjust path as necessary */
  background-size: cover;
  background-position: center;
  margin-left: 1%;
  z-index: 1000;
  
}


.header-title2 {
  font-size: 16.5px;
  font-weight: 500;
  margin: 0;
  font-family: inherit;
}


.bx1 {
height: 100vh;
width: 100vw;
margin: 0;
padding: 0;
background-color: #ebebeb; /* Replace with your desired color */
overflow: hidden; /* Optional: prevent scrollbars */
}



.dilldosca {
  font: 1em sans-serif;
  padding: 84px;
   padding-top: 120px; 
}

#form1 {
  width: 599px;
  display: block;
  margin: 0 auto;
  position: relative;
  border: 1px solid rgb(225, 229, 235);
  border-radius: 3px;
  padding: 10px;
  max-width: 900px;
  background-color: rgb(249, 248, 248);
}

.dx {
  color: rgb(237, 240, 244);
  border: 0.002em solid rgb(229, 233, 240);
}
#dx {
  color: rgb(237, 240, 244);
  border: 0.002em solid rgb(229, 233, 240);
}
#codex {
  -webkit-appearance: none;
  border-radius: 0;
  border: 1px solid rgb(219, 223, 223);
  float: left;
  padding: 2.9px 4.5px;
  margin: 1px 0;
  box-sizing: border-box;
  font-size: 16.28px; /* Set the desired font size */
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.codex {
  -webkit-appearance: none;
  border-radius: 0;
  border: 1px solid rgb(219, 223, 223);
  float: left;
  padding: 2.9px 4.5px;
  margin: 1px 0;
  box-sizing: border-box;
  font-size: 16.28px; /* Set the desired font size */
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

#submitx {
  float: right;
  text-align: center;
  box-shadow: inset 0px 1px 0px 0px rgb(54, 95, 176);
  background: linear-gradient(to bottom, rgb(54, 95, 176) 5%, rgb(54, 95, 176) 100%);
  background-color: rgb(54, 95, 176);
  border: 1px solid rgb(54, 95, 176);
  display: inline-block;
  cursor: pointer;
  color: rgb(241, 239, 239);
  font-family: Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: bold;
  margin-top: -4px;
  padding: 7px 11.5px;
  border-radius: 3px;
}

.submitx {
  float: right;
  text-align: center;
  box-shadow: inset 0px 1px 0px 0px rgb(54, 95, 176);
  background: linear-gradient(to bottom, rgb(54, 95, 176) 5%, rgb(54, 95, 176) 100%);
  background-color: rgb(54, 95, 176);
  border: 1px solid rgb(54, 95, 176);
  display: inline-block;
  cursor: pointer;
  color: rgb(241, 239, 239);
  font-family: Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: bold;
  margin-top: -4px;
  padding: 7px 11.5px;
  border-radius: 3px;
}

#prraqkaj {
  float: left;
  font-size: 14px;
  text-align: left;
  font-family: Helvetica, Arial, sans-serif;
}

#mllekodi {
  color: rgb(46, 83, 157);
  cursor: pointer;
  text-decoration: none;
  float: left;
  font-size: 12px;
  text-align: left;
  font-family: Helvetica, Arial, sans-serif;
}

.mobile-only {
  display: none;
}

/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
  .dilldosca {
    padding: 4px; /* Adjusted padding for smaller screens */
    padding-top: 100px; /* Ensure space for fixed header */
  }

  #form1 {
    width: 95%; /* Slightly reduced to fit better */
    display: block;
    margin: 0 auto;
    position: relative;
    border: 1px solid rgb(225, 229, 235);
    border-radius: 3px;
    padding: 10px;
    max-width: 900px;
    background-color: rgb(249, 248, 248);
  }

  form[name='form2'] {
    display: block; /* Show form2 on smaller screens */
  }

  #form2 {
    padding: 15px; /* Adjusted form padding for smaller screens */
  }

  .codex, .submitx {
    padding: 10px; /* Adjusted input padding for smaller screens */
    box-sizing: border-box;
  }

  .fore2 {
    font-size: 14px; /* Adjusted font size */
    color: #555; /* Adjusted text color */
    text-align: center; /* Adjusted text alignment */
    margin: 5px 0; /* Adjusted margins */
  }

  .mobile-only {
    display: block;
  }
}
