.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}


.main-container {
  display: flex;
  flex: 1;
}

/* ------------- custom scroll bar ---------- */

/* Style for WebKit browsers (Chrome, Safari) */
.sidebar::-webkit-scrollbar {
  width: 8px;
  /* Width of the scrollbar */
}

.sidebar::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Background of the scrollbar track */
  border-radius: 10px;
  /* Optional: Rounded corners */
}

.sidebar::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  /* Color of the scrollbar thumb */
  border-radius: 10px;
  /* Rounded corners */
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
  /* Color of the scrollbar thumb on hover */
}

.sidebar {
  font-family: inherit;
  width: 400px;
  background-color: #fff;
  /* padding: 10px; */
  box-shadow: rgba(0, 0, 0, 0.12) 0px 5px 15px;
  position: relative;
  overflow-y: auto;
  height: 100vh;
  /* padding: 10px  20px !important ; */
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  list-style: none;
  /* : 20px; */
}

.sidebar ul:last-child {
  /* list-style-type: none;
  padding: 0;
  list-style: none; */
  /* : 20px; */
  /* border: 1px solid red; */
  margin-left: 1rem;
}

.sidebar-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 1.1425rem;
  font-weight: 600;
  color: #050505;
  /* line-height: 2.5; */
  cursor: pointer;
  position: relative;
  padding: 0px 5px 10px 10px;
  border-radius: 5px;
  /* border: 1px solid red; */
}

.active {
  background-color: #DFE9F2;
}

.active>.icon-circle {
  background-color: #1877F2 !important;
  /* color: white !important; */
}

.sidebar-item:hover {
  background-color: #F2F2F2;
}

.sidebar-item .icon-circle {
  width: 40px;
  height: 40px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e0e0e0;
  border-radius: 50%;
  margin-right: 10px;
}

.sidebar-item i {
  color: #333131;
  
}



.sidebar-item .arrow {
  position: absolute;
  right: 10px;
  border: solid #666565;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 3.4px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.sidebar-item .arrow.down {
  transform: rotate(45deg);
}


/* Style for WebKit browsers (Chrome, Safari) */
.content-container::-webkit-scrollbar {
  width: 8px;
  /* Width of the scrollbar */
}

.content-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Background of the scrollbar track */
  border-radius: 10px;
  /* Optional: Rounded corners */
}

.content-container::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  /* Color of the scrollbar thumb */
  border-radius: 10px;
  /* Rounded corners */
}

.content-container::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
  /* Color of the scrollbar thumb on hover */
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }
  
  code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
  }

.content-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  /* padding: 20px; */
  /* background-color: #fff; */
  width: 75%;
}


.white-bg {
  background-color: #FFFF;
}

.page-content-container {
  background-color: #eef2f5 !important;
}
.content {
  flex: 1;
  width: 700px;
  margin: 20px auto;
  font-size: 16.6px;
}

.legalBtn {
  background-color: #1877F2;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  font-weight: bolder;
  font-size: 15px;
}


@media screen and (max-width: 1100px) {

  .sidebar {
    width: 32%;
    background-color: #f5f6f7;
    padding: 10px 20px;
  }
}

@media screen and (max-width: 1300px) {

  .content {
    flex: 1;
    width: 90%;
    margin: auto;
  }

  .sidebar {
    width: 32%;
    background-color: #f5f6f7;
    padding: 0px 15px !important;
  }


  .content-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    height: 95vh;
    /* padding: 20px; */
    /* background-color: #fff; */
    width: 75%;
  }

}

.sidebar {
  /* width:20%; */
  /* background-color: #f5f6f7; */
  padding: 0px 15px !important;
}

.content h1 {
  font-size: 1.5rem;
  color: #050505;
}

/* 188bf5 */
.linkreport {
  color: #188bf5;
  /* list-style: none !important; */
  text-decoration: none;
}

hr {
  border: none;
  border-top: 1px solid #e5e5e5;
  margin: 20px 0;
}

.faq-section {
  margin-top: 30px;
  /* border: 1px solid red; */
}

.faq-toggle {
  width: 100%;
  padding: 15px;
  margin-top: 10%;
  background-color: #fff;
  border-radius: 5px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #050505;
}

.faq-toggle:hover {
  background-color: #F2F2F2;
}

.faq-toggle .arrow {
  border: solid #050505;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}


.footer {
  background-color: #eef2f5;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  /* border-top: 1px solid red; */
}

.footer-container {

  width: 700px;
  margin: auto;
}

@media screen and (max-width: 1000px) {
  .footer-container {
    width: 90%;
  }


  .content-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    height: 95vh;
  }
}

.footer-links {
  text-align: left;
  padding: 0;
  display: flex;
  /* justify-content: flex-start; */
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  /* border: 1px solid green; */
}


.footer-links .link {
  width: 30%;
  font-size: 0.9375rem;
  color: #656768;
  line-height: 45px;
  /* border: 1px solid purple; */
}

.footer-links ul li a {
  color: #4267b2;
  text-decoration: none;
}

.footer-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  color: #666;
  font-size: 0.9rem;
  text-align: center;
}

.footer-meta a {
  color: #4267b2;
  text-decoration: none;
}

.footer hr {
  border: none;
  border-top: 1px solid #ccc;
  margin: 20px 0;
}

.meta .para {
  display: none;
  color: #666;
  font-size: 0.9rem;

  padding: 20px 0px;
}

.engLangBtn button {
  background-color: #FFFFFF;
  border: none;
  padding: 7px 0px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500 !important;
  display: flex;
  align-items: center;
  color: #050505 !important;
  justify-content: center;
  display: none;
}

.engLangBtn button i {
  margin: 0px 10px;
  /* border: 1px solid red; */
  text-align: center;
}

.fa-globe {
  color: #050505 !important;
}

/* Footer styles */
@media (max-width: 800px) {
  .main-container {

    overflow-x: hidden;
  }

  .content {
    /* overflow-x: hidden; */
    /* overflow-y: auto; */
    width: 92%;

    /* height: 90vh !important; */

  }

  .footer {
    /* display: block; */
  }


  .meta .para {
    /* display: block; */

  }

  .footer-container {
    /* border: 1px solid blue; */

  }

  .footer-links {
    display: block;
    /* border: 1px solid green; */
    /* flex-direction: column; */


  }


  .footer-links .link {
    width: 100%;
  }

  .engLangBtn button {
    display: block;
    font-weight: 600;
    /* medium weight */
    font-size: .9375rem;
    /* adjust size as needed */
    color: #666;
    /* adjust color as needed */
  }

  .footer-meta img {
    /* display: none; */
    margin-left: -20px !important;
  }

  hr {
    /* display: none; */
  }
}



/* Footer.css */
.footer {
  /* background-color: #f1f1f1; */
  /* padding: 20px; */
  position: relative;
  /* width: 100%; */
  bottom: 0;
  /* text-align: center; */
}